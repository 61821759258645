<template>
  <div class="cfp-component">
    <SpeakerInfo
      v-if="!isSpeakerInfoFilled && !isTalkInfoFilled"
      @submit-speaker-data="goToTalk"
    />
    <transition name="fade">
      <WithFormSelectedData
        v-if="isSpeakerInfoFilled || isTalkInfoFilled"
        :selected-data="cfpDisplayData"
      >
        <template #body>
          <transition name="fade">
            <Preloader :loading="isCFPLoading" />
          </transition>
          <transition name="fade">
            <TalkInfo
              v-if="
                isSpeakerInfoFilled &&
                !isTalkInfoFilled &&
                !isCFPSuccess &&
                !isCFPError
              "
              :tracks="tracks"
              @submit-talk-data="goToContact"
            />
          </transition>
          <transition name="fade">
            <ContactInfo
              v-if="
                isSpeakerInfoFilled &&
                isTalkInfoFilled &&
                !isCFPSuccess &&
                !isCFPError
              "
              @submit-contact-data="goToSubmit"
            />
          </transition>
          <transition name="fade">
            <Heading
              noDecoration
              v-if="isCFPSuccess"
              type="h1"
              text-align="center"
              style="margin-top: 40%"
            >
              Thank you for your submission!
            </Heading>
          </transition>
          <transition name="fade">
            <Heading
              noDecoration
              v-if="isCFPError"
              type="h1"
              text-align="center"
              style="margin-top: 40%"
            >
              Sorry, something went wrong
            </Heading>
          </transition>
        </template>
      </WithFormSelectedData>
    </transition>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import WithFormSelectedData from "@/components/common/WithFormSelectedData";
import SpeakerInfo from "./SpeakerInfo";
import TalkInfo from "./TalkInfo";
import ContactInfo from "./ContactInfo";
import { mapActions } from "vuex";
// import useSqsFormSend from "@/assets/js/composables/sqsFormSend";
// import { transformForm } from "@/assets/js/utils";
// import { validateForm } from "@/assets/js/validation";

export default {
  name: "CfpComponent",
  props: {},
  components: {
    Preloader,
    Heading,
    WithFormSelectedData,
    SpeakerInfo,
    TalkInfo,
    ContactInfo,
  },
  data: () => ({
    isSpeakerInfoFilled: false,
    isTalkInfoFilled: false,
    isCFPLoading: false,
    isCFPSuccess: false,
    isCFPError: false,
    cfpDisplayData: [],
    cfpForm: {},
    isTracksLoading: false,
    tracks: [],
  }),
  mounted() {
    this.getTracks();
  },
  methods: {
    ...mapActions(["GET", "POST"]),
    getTracks() {
      this.isTracksLoading = true;
      return this.GET({
        route: `public/event/${this.$route.params.id}/track`,
      })
        .then((resp) => {
          /*console.log("get tracks", resp.data);*/
          this.tracks = resp.data;
          this.isTracksLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isTracksLoading = false;
        });
    },
    goToTalk(speakerInfo) {
      this.isSpeakerInfoFilled = true;
      this.cfpForm = { ...this.cfpForm, ...speakerInfo };
      this.cfpDisplayData = [
        ...this.cfpDisplayData,
        { title: "General information", ...speakerInfo },
      ];
    },
    goToContact(talkInfo) {
      const [talkData, talkDisplayData] = talkInfo;

      this.isTalkInfoFilled = true;
      this.cfpForm = { ...this.cfpForm, ...talkData };
      this.cfpDisplayData = [
        ...this.cfpDisplayData,
        { title: "Talk information", ...talkDisplayData },
      ];
    },
    goToSubmit(contactData) {
      this.cfpForm = { ...this.cfpForm, ...contactData };
      this.submitCFP();
    },
    submitCFP() {
      this.isCFPLoading = true;
      // const isValid = validateForm(this.cfpForm);
      // if (!isValid) return;
      this.POST({
        formRoute: false,
        route: `public/event/${this.$route.params.id}/submission/`,
        data: this.cfpForm,
      })
        .then((res) => {
          const query = {
            ...res,
            form_type: "cfp",
            event_id: this.$route.params.id,
          };
          this.POST({
            formRoute: true,
            route: "form/cfp",
            data: query,
          })
            .then(() => {
              this.isCFPSuccess = true;
              this.isCFPLoading = false;
            })
            .catch(() => {
              this.isCFPError = true;
              this.isCFPLoading = false;
            });
        })
        .catch((err) => {
          this.POST({
            formRoute: true,
            route: "form/cfp/error",
            data: err,
          });
          this.isCFPError = true;
          this.isCFPLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
</style>
